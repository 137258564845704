.slider {
  overflow: hidden;
  position: relative;
  height: 75vh;
  color: #fff;
}

/*.slider__top-heading {*/
/*z-index: 12;*/
/*position: absolute;*/
/*left: 0;*/
/*top: 100px;*/
/*width: 100%;*/
/*text-align: center;*/
/*font-size: 16px;*/
/*text-transform: uppercase;*/
/*letter-spacing: 2.5px;*/
/*transition: all 0.5s 1s;*/
/*transform: translateY(-30px);*/
/*opacity: 0;*/
/*}*/
.slider.s--ready .slider__top-heading {
  transform: translateY(0);
  opacity: 1;
}

.slider__slides {
  position: relative;
  height: 100%;
}

.slider__slide {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
}

.slider__slide.s--active {
  pointer-events: auto;
  animation-name: zoomIn;
  animation-duration: 5s;
  transition-timing-function: ease-in-out;
}

.slider__slide-content {
  z-index: 6;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;
  text-transform: uppercase;
  line-height: 1;
}

.slider__slide-subheading {
  margin-bottom: 20px;
  font-size: 24px;
  letter-spacing: 2px;
  transform: translateY(20px);
  opacity: 0;
  transition: 0.5s;
  text-align: center;
}

.slider__slide.s--active .slider__slide-subheading {
  transition-delay: 0.65s;
  opacity: 1;
  transform: translateY(0);
}

.slider__slide-heading {
  display: flex;
  margin-bottom: 20px;
  font-size: 35px;
  letter-spacing: 10px;
}

.slider__slide-heading span {
  display: block;
  opacity: 0;
  transform: translateY(-60px);
  transition: all 0.3333333333s;
}

.slider__slide.s--prev .slider__slide-heading span {
  transform: translateY(60px);
}

.slider__slide.s--active .slider__slide-heading span {
  opacity: 1;
  transform: translateY(0);
}

.slider__slide-heading span:nth-child(1) {
  transition-delay: 0s;
}

.slider__slide.s--active .slider__slide-heading span:nth-child(1) {
  transition-delay: 0.3333333333s;
}

.slider__slide-heading span:nth-child(2) {
  transition-delay: 0.1s;
}

.slider__slide.s--active .slider__slide-heading span:nth-child(2) {
  transition-delay: 0.4333333333s;
}

.slider__slide-heading span:nth-child(3) {
  transition-delay: 0.2s;
}

.slider__slide.s--active .slider__slide-heading span:nth-child(3) {
  transition-delay: 0.5333333333s;
}

.slider__slide-heading span:nth-child(4) {
  transition-delay: 0.3s;
}

.slider__slide.s--active .slider__slide-heading span:nth-child(4) {
  transition-delay: 0.6333333333s;
}

.slider__slide-heading span:nth-child(5) {
  transition-delay: 0.4s;
}

.slider__slide.s--active .slider__slide-heading span:nth-child(5) {
  transition-delay: 0.7333333333s;
}

.slider__slide-heading span:nth-child(6) {
  transition-delay: 0.5s;
}

.slider__slide.s--active .slider__slide-heading span:nth-child(6) {
  transition-delay: 0.8333333333s;
}

.slider__slide-heading span:nth-child(n + 7) {
  transition-delay: 0.6s;
}

.slider__slide.s--active .slider__slide-heading span:nth-child(n + 7) {
  transition-delay: 0.9333333333s;
}

.slider__slide-readmore {
  position: relative;
  font-size: 14px;
  text-transform: lowercase;
  backface-visibility: hidden;
  transform: translateY(-20px);
  cursor: pointer;
  opacity: 0;
  transition: 0.5s;
}

.slider__slide.s--active .slider__slide-readmore {
  transition-delay: 0.65s;
  opacity: 1;
  transform: translateY(0);
}

.slider__slide-readmore:before {
  content: '';
  position: absolute;
  left: -2px;
  top: -3px;
  width: calc(100% + 4px);
  height: calc(100% + 6px);
  background: rgba(255, 255, 255, 0.4);
  transform: scaleX(0.3);
  transform-origin: 0 50%;
  transition: transform 0.3s;
}

.slider__slide-readmore:hover:before {
  transform: scaleX(1);
}

.slider__slide-parts {
  position: absolute;
  left: 0;
  top: 0;
  display: flex;
  width: 100%;
  height: 100%;
}

.slider__slide-parts:after {
  content: '';
  z-index: 5;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.1);
}

.slider__slide-part {
  position: relative;
  width: 25%;
  height: 100%;
}

.slider__slide-part-inner {
  overflow: hidden;
  position: relative;
  width: 100%;
  height: 100%;
  background-size: 0 0;
  background-repeat: no-repeat;
  transition: transform 0.5s ease-in-out;
}

.slider__slide-part-inner:before {
  content: '';
  position: absolute;
  width: 100vw;
  height: 100%;
  background-image: inherit;
  background-size: cover;
  background-position: center center;
  transition: opacity 0.25s;
  opacity: 0;
}

.slider__slide-part:nth-child(1) .slider__slide-part-inner {
  z-index: 3;
  transition-delay: 0.24s;
  transform: translateX(-32.5%);
}

.slider__slide.s--active
  .slider__slide-part:nth-child(1)
  .slider__slide-part-inner {
  transition-delay: 0.28s;
}

.slider__slide-part:nth-child(1) .slider__slide-part-inner:before {
  left: 0vw;
  transition-delay: 0.365s;
}

.slider__slide.s--active
  .slider__slide-part:nth-child(1)
  .slider__slide-part-inner:before {
  transition-delay: 0.28s;
}

.slider__slide-part:nth-child(2) .slider__slide-part-inner {
  z-index: 2;
  transition-delay: 0.16s;
  transform: translateX(-65%);
}

.slider__slide.s--active
  .slider__slide-part:nth-child(2)
  .slider__slide-part-inner {
  transition-delay: 0.36s;
}

.slider__slide-part:nth-child(2) .slider__slide-part-inner:before {
  left: -25vw;
  transition-delay: 0.285s;
}

.slider__slide.s--active
  .slider__slide-part:nth-child(2)
  .slider__slide-part-inner:before {
  transition-delay: 0.36s;
}

.slider__slide-part:nth-child(3) .slider__slide-part-inner {
  z-index: 1;
  transition-delay: 0.08s;
  transform: translateX(-97.5%);
}

.slider__slide.s--active
  .slider__slide-part:nth-child(3)
  .slider__slide-part-inner {
  transition-delay: 0.44s;
}

.slider__slide-part:nth-child(3) .slider__slide-part-inner:before {
  left: -50vw;
  transition-delay: 0.205s;
}

.slider__slide.s--active
  .slider__slide-part:nth-child(3)
  .slider__slide-part-inner:before {
  transition-delay: 0.44s;
}

.slider__slide-part:nth-child(4) .slider__slide-part-inner {
  z-index: 0;
  transition-delay: 0s;
  transform: translateX(-130%);
}

.slider__slide.s--active
  .slider__slide-part:nth-child(4)
  .slider__slide-part-inner {
  transition-delay: 0.52s;
}

.slider__slide-part:nth-child(4) .slider__slide-part-inner:before {
  left: -75vw;
  transition-delay: 0.125s;
}

.slider__slide.s--active
  .slider__slide-part:nth-child(4)
  .slider__slide-part-inner:before {
  transition-delay: 0.52s;
}

.slider__slide.s--active .slider__slide-part-inner {
  transform: translateX(0);
  transition-timing-function: ease;
}

.slider__slide.s--active .slider__slide-part-inner:before {
  opacity: 1;
}

.slider__control {
  z-index: 100;
  position: absolute;
  left: 50px;
  top: 50%;
  width: 50px;
  height: 50px;
  margin-top: -25px;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.4);
  transform: translateX(-50px);
  opacity: 0;
  transition: all 0.5s 1s;
  cursor: pointer;
}

.slider__control:before {
  content: '';
  position: absolute;
  left: 50%;
  top: 50%;
  width: 20px;
  height: 20px;
  margin-left: -10px;
  margin-top: -10px;
  border: 2px solid #000;
  border-bottom: none;
  border-right: none;
  transform: translateX(5px) rotate(-45deg);
}

.slider__control--right {
  left: auto;
  right: 50px;
  transform: translateX(50px);
}

.slider__control--right:before {
  transform: translateX(-5px) rotate(135deg);
}

.slider.s--ready .slider__control {
  transform: translateX(0);
  opacity: 1;
}

.icon-link {
  z-index: 100;
  position: absolute;
  left: 5px;
  bottom: 5px;
  width: 32px;
}

.icon-link img {
  width: 100%;
  vertical-align: top;
}

.icon-link--twitter {
  left: auto;
  right: 5px;
}

@keyframes zoomIn {
  0% {
    transform: scale(1);
  }
  /*25% {*/
  /*transform: scale(1.05)*/
  /*}*/
  99% {
    transform: scale(1.1);
  }
  /*100% {
      transform: scale(1.15)
  }*/
}

@media screen and (max-width: 425px) {
  .slider__slide-heading {
    font-size: 12px;
    letter-spacing: 5px;
  }
  .slider__slide-subheading {
    font-size: 15px;
    letter-spacing: 2px;
  }
}
