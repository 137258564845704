/*greek*/
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local('Open Sans Regular'), local('OpenSans-Regular'),
    url(https://fonts.gstatic.com/s/opensans/v17/mem8YaGs126MiZpBA-UFVp0bbck.woff2)
      format('woff2');
  unicode-range: U+0370-03FF;
}

/*latin*/
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local('Open Sans Regular'), local('OpenSans-Regular'),
    url(https://fonts.gstatic.com/s/opensans/v17/mem8YaGs126MiZpBA-UFVZ0b.woff2)
      format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: 'Open Sans', sans-serif;
}

h1 {
  margin: 15px 0 30px 0;
}

h2 {
  margin: 10px 0 20px 0;
}

h3 {
  margin: 5px 0 15px 0;
}

p {
  margin: 0px 0 10px 0;
}

ul {
  margin-left: 20px;
}

hr {
  margin: 20px 0;
}

/* Αυτό ΜΗΤΣΟ θα το βγάλεις από εδώ!!! */

